<div class="modal">
    <div class="container model-container {{context.customClass}}">
        <div class="modal-header">
            <h2 class="modal-heading">{{context.title}}</h2>
            <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32"
            height="32" alt="Close Icon">
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="content">
                    <p class="smaller note">{{context.note}} <span class="link"
                            (click)="addNewPayment()"><b>{{context.noteLinkText}}</b></span>. </p>
                </div>
                <div class="form-section payment-form">
                    <form [formGroup]="ccForm" class="ccForm">
                        <div class="expiry-section">
                            <label for="cardExpirationMonth">Expiry date <span class="required-field">*</span></label>
                            <div class="selection-section">
                                <div class="select no-margin-left">
                                    <select id="cardExpirationMonth" data-cy="cardExpirationMonth"
                                        name="cardExpirationMonth" formControlName="cardExpirationMonth"
                                        autocomplete="cc-exp-month">
                                        <option *ngFor="let month of months" [value]="month.value">
                                            {{ month.label }}
                                        </option>
                                    </select>
                                </div>
                                <span class="sperator">/</span>
                                <div class="select">
                                    <select id="cardExpirationYear" data-cy="cardExpirationYear"
                                        name="cardExpirationYear" formControlName="cardExpirationYear"
                                        autocomplete="cc-exp-year">
                                        <option *ngFor="let expYear of expirationYearRange" [value]="expYear">
                                            {{expYear}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <label class="validation-message"
                        *ngIf="(ccForm.controls.cardExpirationMonth.touched || ccForm.controls.cardExpirationYear.touched) && ccForm.errors?.cardExpirationInvalid && (ccForm.controls.cardExpirationMonth.valid || !ccForm.controls.cardExpirationYear.valid)">
                        Expiration date must be valid
                    </label>
                    </form>
                </div>
                <div class="form-section address-form">
                    <h5 class="title">Billing address</h5>
                    <div class="populated" *ngIf="!isEditMode">
                        <p class="smaller address">{{context?.paymentMethod?.card_profile?.billing_address?.line1}}.
                            <span class="edit" data-cy="editPaymentAddress" (click)="enableEditAddress()"><img
                                    src="/assets/img/edit-white.svg" alt="edit" name="edit" /> <b> Edit
                                    address</b></span>
                        </p>
                        <p class="smaller address">{{context?.paymentMethod?.card_profile?.billing_address?.city}},
                            {{context?.paymentMethod?.card_profile?.billing_address?.state}}</p>
                        <p class="smaller address">
                            {{context?.paymentMethod?.card_profile?.billing_address?.postal_code}}</p>
                    </div>
                    <form [formGroup]="addressForm" class="addressForm" *ngIf="!!isEditMode">
                        <fieldset class="first-row">
                            <div class="address-section">
                                <label for="addressLookup">Address line <span class="required-field">*</span></label>
                                <input formControlName="line1" id="addressLookup" data-cy="addressLookup" matInput
                                    type="search" list-formatter="description" #addressLookup type="search"
                                    (focus)="setFoucs = true" (blur)="setFoucs = false" class="geolocate"
                                    [matAutocomplete]="auto" required (input)="changedAddress()" />
                                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ option?.description }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div class="address-section-validation">
                                <p class="validation-message" data-cy="addressLineRequiredMsg"
                                    *ngIf="addressForm.controls.line1.touched && addressForm.controls.line1.hasError('required')">
                                    Address is required. </p>
                            </div>
                        </fieldset>
                        <fieldset class="second-row">
                            <div class="state-section">
                                <label for="state">State <span class="required-field">*</span></label>
                                <input class="stateField" data-cy="stateField" type="text" name="state"
                                    formControlName="state" autocomplete="address-level1" maxlength="2" minlength="2">
                            </div>
                            <div class="state-section-validations">
                                <p class="validation-message" data-cy="stateRequiredMsg"
                                    *ngIf="addressForm.controls.state.touched && addressForm.controls.state.hasError('required')">
                                    State is required. </p>
                                <p class="validation-message" data-cy="stateInvalidMsg"
                                    *ngIf="addressForm.controls.state.dirty&& addressForm.controls.state.value && addressForm.controls.state.errors?.pattern">
                                    State is invalid. </p>
                            </div>
                            <div class="city-section">
                                <label for="city">City <span class="required-field">*</span></label>
                                <input class="cityField" data-cy="cityField" type="text" name="city"
                                    formControlName="city" autocomplete="address-level2">
                            </div>
                            <div class="city-section-validations">
                                <p class="validation-message" data-cy="cityRequiredMsg"
                                    *ngIf="addressForm.controls.city.touched && addressForm.controls.city.hasError('required')">
                                    City is required. </p>
                                <p class="validation-message" data-cy="cityInvalidMsg"
                                    *ngIf="addressForm.controls.city.dirty&& addressForm.controls.city.value && addressForm.controls.city.errors?.pattern">
                                    City is invalid. </p>
                            </div>
                            <div class="postal-section">
                                <label for="postal_code">ZIP code <span class="required-field">*</span></label>
                                <input id="postal_code" data-cy="zipField" name="postal_code" #postal_code type="text"
                                    formControlName="postal_code" autocomplete="postal-code" maxlength="10"
                                    minlength="5" (valueChanged)="addressDetails($event)" />
                            </div>
                            <div class="postal-section-validation">
                                <p class="validation-message" data-cy="zipRequiredMsg"
                                    *ngIf="addressForm.controls.postal_code.touched && addressForm.controls.postal_code.hasError('required')">
                                    ZIP code is required. </p>
                                <p class="validation-message" data-cy="zipInvalidMsg"
                                    *ngIf="addressForm.controls.postal_code.dirty && addressForm.controls.postal_code.value && addressForm.controls.postal_code.errors?.pattern">
                                    ZIP code is invalid. </p>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="form-section modal-actions">
                    <button class="button primary" data-cy="updatePaymentBtn" (click)="savePaymentInfo()"
                        [uiBlockButton]="processingRequest">
                        Update payment
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>