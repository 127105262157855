<div class="modal">
    <div class="container model-container full {{context.customClass}}">
        <div class="modal-header">
            <h2 class="modal-heading">{{context.title}}</h2>
            <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32"
                height="32" alt="Close Icon">
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="content">
                    <p class="mdn">Mobile Number: <b>{{context.mdn | phone}}</b></p>
                </div>
                <div class="payments">
                    <select class="payments-list" [(ngModel)]="selectedCard" name="payment-list" id="payment-list">
                        <option disabled *ngIf="!context.paymentList">No Credit Card Available</option>
                        <option *ngFor="let card of context.paymentList" [ngValue]="card">
                            {{getPaymentDetails(card?.payment_profile_id)}}
                        </option>
                    </select>
                    <div class="action">
                        <button class="button primary" (click)="savePayment()"> {{context.buttonText}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>