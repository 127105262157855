import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/app.service';
import { IFirebaseAddress } from '@ztarmobile/zwp-service-backend-v2';

export class ShippingModalContext {
  public title: string;
  public shippingAddress?: IFirebaseAddress;
  public customClass?: string;
}

@Component({
  selector: 'app-shipping-address-modal',
  templateUrl: './shipping-address-modal.component.html'
})
export class ShippingAddressModalComponent {
  public context: any;
  public address: IFirebaseAddress;
  public isValidAddress: boolean;

  private editedAddress: IFirebaseAddress = {} as IFirebaseAddress;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<ShippingModalContext>,
    private appState: AppState
  ) {
    this.context = data;
    this.address = !!this.context.shippingAddress ? this.context.shippingAddress : {} as IFirebaseAddress;
    this.editedAddress = this.address;
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(result): void {
    this.beforeDismiss();
    this.dialog.close(result);
  }

  public handleLoading(event): void {
    this.appState.loading = event;
  }

  public setValidAddress(isValid: boolean): void {
    this.isValidAddress = isValid;
  }

  public addressChanged(address: IFirebaseAddress): void {
    this.editedAddress =  address;
  }
 
  public saveAddress(): void {
    if(!!this.isValidAddress) {
      this.beforeDismiss();
      this.dialog.close(this.editedAddress);
    }
  }
}
