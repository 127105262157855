<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon" width="32" height="32"
      alt="Close Icon" loading="lazy">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
    </div>
    <div class="modal-body">
      <form #confirmPasswordForm="ngForm">
        <div class="modal-content">
          <p>
            {{context.message}}
          </p>
          <fieldset>
            <label for="confirmCurrentPassword">Current Password:</label>
            <input id="confirmCurrentPassword" type="password" name="confirmCurrentPassword"
              [(ngModel)]="confirmCurrentPassword" #currentPassword required autocomplete="current-password">
            <label class="validation-message" *ngIf="confirmPasswordForm.touched && !confirmPasswordForm.valid">
              Password is required
            </label>
          </fieldset>
          <div class="modal-actions">
            <button class="button primary" (click)="submitPassword()">OK</button>
            <button class="button secondary" (click)="closeDialog()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>