import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountPaymentProfile } from '@ztarmobile/zwp-service-backend-v2/lib/models';

export class PlanCreditCardContext {
  public paymentList: Array<AccountPaymentProfile>;
  public title: string;
  public mdn: string;
  public buttonText: string;
  public customClass?: string;
  public profileKey?: string;
}

@Component({
  selector: 'app-select-payment-modal',
  templateUrl: './select-payment-modal.component.html'
})
export class SelectCCModalComponent {
  public context: any;
  public selectedCard: AccountPaymentProfile;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialogRef<PlanCreditCardContext>
  ) {
    this.context = data;
    if (!!this.context.paymentList && !!this.context.profileKey) {
      this.selectedCard = this.context.paymentList.find((card) => card?.payment_profile_id === this.context?.profileKey);
    }
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  public getPaymentDetails(id): string {
    if (!!this.context.paymentList) {
      const planMethod = this.context.paymentList.find((method) => method?.payment_profile_id === id);
      let label = '';
      if (!!planMethod) {
        label = planMethod?.card_profile?.brand + ' Ending in ' + planMethod.card_profile?.last_4 + ', Expiration Date ' + planMethod?.card_profile?.expiry_month + '/' + planMethod?.card_profile?.expiry_year;
      }
      return label;
    }
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public savePayment(): void {
    this.closeDialog(this.selectedCard?.payment_profile_id);
  }

  public closeDialog(action?: any): void {
    this.beforeDismiss();
    this.dialog.close(action);
  }
}
