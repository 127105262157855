<div class="modal">
    <div class="container model-container full {{context.customClass}}">
        <div class="modal-header">
            <h4 class="modal-heading">{{context.title}}</h4>
            <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog(false)" class="close-icon" width="32"
            height="32" alt="Close Icon">
        </div>
        <div class="modal-body">
            <div class="modal-content">
                <div class="form-section">
                    <lib-app-address-lookup [showAlias]="true" [displayedAddress]="address"
                        (isValid)="setValidAddress($event)" (appStateLoading)="handleLoading($event)" [language]="'en'"
                        (addressChange)="addressChanged($event)"></lib-app-address-lookup>
                </div>
                <div class="modal-actions">
                    <button class="button primary" (click)="saveAddress()">
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>