import { Injectable } from "@angular/core";
import { UserAccountService } from "@ztarmobile/zwp-service-backend-v2";

@Injectable({
    providedIn: 'root'
})

export class UserDataService {

    constructor(private accountService: UserAccountService) {
    }

    public callSyncApi(planId: string, mdn: string): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.accountService.getMdnDetails(planId, mdn, 'MDNSIMPLE').then((data) => {
                resolve(data);
            }, (error) => {
                reject(error);
            })
        });
    }
}
