<form [formGroup]="addressFieldsForm" class="address-form">
  <div class="form-section">
    <fieldset class="address-lookup" *ngIf="showAlias">
      <label class="outlined-label-input">
        <input id="addressAlias" data-cy="addressName" type="text" formControlName="alias"
        (change)="addressFieldChanged($event)" [readonly]="readonly" maxlength="50"
        (input)="validateAlias(addressFieldsForm.controls.alias.value)" placeholder=" " />
        <span>Address Name<p class="star">*</p></span>
      </label>

      <div class='form-text error'>
        <p class="caption validation-message" id="addressNameRequiredMsg"
          *ngIf="addressFieldsForm.controls.alias.touched && !addressFieldsForm.controls.alias.value && addressFieldsForm.controls.alias.hasError('required')">
          Name is a required field
        </p>
        <p class="caption validation-message" id="addressNameInvalidMsg"
          *ngIf="addressFieldsForm.controls.alias.dirty && !!addressFieldsForm.controls.alias.value && !isValidName">
          Name is invalid
        </p>
      </div>
    </fieldset>

    <div class="inputs-row">
      <fieldset class="address-lookup">
        <label class="outlined-label-input">
          <input formControlName="address1" matInput type="search" list-formatter="description" id="addressLookup"
            #addressLookup class="geolocate" [matAutocomplete]="auto" required (input)="changedAddress()"
            (change)="validateAddress1(addressFieldsForm.controls.address1.value)" [readonly]="readonly"
            placeholder=" " />
          <span>
            Address Line
            <p class="star">*</p>
          </span>
        </label>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            {{ option?.description }}
          </mat-option>
        </mat-autocomplete>
        <i class="icon-search input-search-icon"></i>
        <div class='form-text error'>
          <p class="caption validation-message" id="address1RequiredMsg"
            *ngIf="addressFieldsForm.controls.address1.touched && addressFieldsForm.controls.address1.hasError('required')">
            Address is a Required Field
          </p>
        </div>
      </fieldset>
      <fieldset class="suite-field">
        <label class="outlined-label-input">
          <input id="address2" type="text" formControlName="address2" [readonly]="readonly"
            (change)="addressFieldChanged($event)" autocomplete="address-line2" placeholder=" " />
          <span>Suite/Apt. No.</span>
        </label>
      </fieldset>
    </div>
  </div>
  <div class="inputs-row" [class.active]="hasDetails" [class.hidden]="!hasDetails">
    <fieldset class="city-field">
      <label class="outlined-label-input">
        <input id="billingCity" type="text" formControlName="city" [readonly]="readonly"
          (change)="addressFieldChanged($event)" autocomplete="address-level2"
          (blur)="validateCityName(addressFieldsForm.controls.city.value)" placeholder=" " />
        <span>City <p class="star">*</p></span>
      </label>

      <div class='form-text error'>
        <p class="caption validation-message" id="cityRequiredMsg"
          *ngIf="addressFieldsForm.controls.city.touched && addressFieldsForm.controls.city.hasError('required')">City
          is a Required Field</p>
      </div>
      <div class='form-text error' id="cityInvalidMsg"
        *ngIf="addressFieldsForm.controls.city.dirty && !isValidCity && addressFieldsForm.controls.city.value">
        <p class="caption validation-message">Invalid City </p>
      </div>
    </fieldset>
    <fieldset class="state-field">
      <label class="outlined-label-input">
        <input id="billingState" type="text" formControlName="state" [readonly]="readonly"
          (change)="addressFieldChanged($event)" autocomplete="address-level1" maxlength="2" minlength="2"
          (blur)="validateStateName(addressFieldsForm.controls.state.value)" placeholder=" " />
        <span>State <p class="star">*</p></span>
      </label>

      <div class='form-text error'>
        <p class="caption validation-message" id="stateRequiredMsg"
          *ngIf="addressFieldsForm.controls.state.touched && addressFieldsForm.controls.state.hasError('required')">
          State
          is a Required Field </p>
      </div>
      <div class='form-text error' id="stateInvalidMsg"
        *ngIf="addressFieldsForm.controls.state.dirty && !isValidState && addressFieldsForm.controls.state.value">
        <p class="caption validation-message">Invalid State </p>
      </div>
    </fieldset>
    <fieldset class="postal-field">
      <label class="outlined-label-input">
        <input id="billingPostal" type="text" formControlName="postalCode" [readonly]="readonly"
          (change)="addressFieldChanged($event)" autocomplete="postal-code" maxlength="10" minlength="5"
          (blur)="validatePostalCode(addressFieldsForm.controls.postalCode.value)" placeholder=" " />
        <span>Postal Code <p class="star">*</p></span>
      </label>

      <div class='form-text error'>
        <p class="caption validation-message" id="postalCodeRequiredMsg"
          *ngIf="addressFieldsForm.controls.postalCode.touched && addressFieldsForm.controls.postalCode.hasError('required')">
          Postal
          Code is a Required Field</p>
      </div>
      <div class='form-text error' id="postalCodeInvalidMsg"
        *ngIf="addressFieldsForm.controls.postalCode.dirty && !isValidPostal && addressFieldsForm.controls.postalCode.value">
        <p class="caption validation-message">Invalid Postal Code </p>
      </div>
    </fieldset>
  </div>
  <input type="hidden" formControlName="country" autocomplete="country">
</form>