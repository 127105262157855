import { Component, ChangeDetectorRef, OnInit, OnDestroy, Inject } from '@angular/core';
import { PlacesAutocompleteService, IAutoCompletePrediction, AccountPaymentService } from '@ztarmobile/zwp-service-backend';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { Subscription } from 'rxjs';
import { AppState } from 'src/app/app.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountPaymentProfile, IPaymentAddress } from '@ztarmobile/zwp-service-backend-v2/lib/models';

export class CreditCardContext {
  public paymentMethod: AccountPaymentProfile;
  public title: string;
  public note: string;
  public noteLinkText: string;
  public customClass?: string;
}

@Component({
  selector: 'app-edit-cc-modal',
  templateUrl: './edit-cc-modal.component.html'
})
export class EditCcModalComponent implements OnInit, OnDestroy {
  public context: CreditCardContext;
  public billingAddress: IPaymentAddress;
  public expirationYearRange: Array<number>;
  public months: { value: string; label: string }[] = [];
  public ccForm: FormGroup;
  public addressForm: FormGroup;
  public processingRequest = false;
  public isEditMode = false;
  public filteredOptions: Observable<Array<IAutoCompletePrediction>>;
  public filteredOptionsSubscription: Subscription;

  private currentDate: Date;
  private streetSearchText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<CreditCardContext>, private formBuilder: UntypedFormBuilder, private cdRef: ChangeDetectorRef,
    private placesAutoCompleteService: PlacesAutocompleteService, private location: PlatformLocation, private appState: AppState) {
    this.context = data;
    location.onPopState(() => { this.beforeDismiss(); this.dialog.close(); });
    this.addressForm = formBuilder.group({
      line1: ['', Validators.required],
      city: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)])],
      state: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z\s]*$/)])],
      postal_code: ['', Validators.compose([Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)])],
    });
    this.currentDate = new Date();
    this.ccForm = formBuilder.group({
      cardExpirationMonth: ['', Validators.required],
      cardExpirationYear: ['', Validators.required],
    }, { validator: this.validExpirationDate('cardExpirationMonth', 'cardExpirationYear') });
    this.ccForm.controls.cardExpirationMonth.setValue(this.context?.paymentMethod?.card_profile?.expiry_month);
    this.ccForm.controls.cardExpirationYear.setValue(this.context?.paymentMethod?.card_profile?.expiry_year);
    this.addressForm.controls.line1.setValue(this.context?.paymentMethod?.card_profile?.billing_address?.line1);
    this.addressForm.controls.city.setValue(this.context?.paymentMethod?.card_profile?.billing_address?.city);
    this.addressForm.controls.state.setValue(this.context?.paymentMethod?.card_profile?.billing_address?.state);
    this.addressForm.controls.postal_code.setValue(this.context?.paymentMethod?.card_profile?.billing_address?.postal_code);
    this.expirationYearRange = [];
    
    const year = parseInt(this.currentDate.getFullYear().toString(), 10);
    this.populateYearRange(year);
    this.populateMonthRange();
    this.billingAddress = {} as IPaymentAddress;
  }

  ngOnInit(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.cdRef.detach();
    this.filteredOptionsSubscription?.unsubscribe();
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }
  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public populateYearRange(currentYear: number): void {
    for (let i = 0; i < 50; i++) {
      this.expirationYearRange.push(currentYear + i);
    }
  }
  public populateMonthRange(): void {
    this.months = [
      { value: '01', label: '01' },
      { value: '02', label: '02' },
      { value: '03', label: '03' },
      { value: '04', label: '04' },
      { value: '05', label: '05' },
      { value: '06', label: '06' },
      { value: '07', label: '07' },
      { value: '08', label: '08' },
      { value: '09', label: '09' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' }
    ];
  }
  public addNewPayment(): void {
    this.closeDialog('new');
  }
  public validExpirationDate(month: string, year: string): any {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      const expMonth = group.controls[month];
      const expYear = group.controls[year];
      const currentYear = this.currentDate.getFullYear();
      const currentMonth = this.currentDate.getMonth() + 1; // Months are 0-indexed

      if (!!this.context.paymentMethod && !!expYear.value && !!expMonth.value) {
        this.context.paymentMethod.card_profile.expiry_month = expMonth.value;
        this.context.paymentMethod.card_profile.expiry_year = expYear.value;
      }

      if (!!expYear.value && !!expMonth.value) {
        const selectedYear = +expYear.value;
        const selectedMonth = +expMonth.value;

        if (selectedYear < currentYear || 
           (selectedYear === currentYear && selectedMonth < currentMonth)) {
          return {
            cardExpirationInvalid: true
          };
        }
      }

      return null;
    };
  }
  public savePaymentInfo(): void {
    this.billingAddress.state = this.addressForm.get('state').value;
    this.billingAddress.city = this.addressForm.get('city').value;
    this.ccForm.markAllAsTouched();
    this.addressForm.markAllAsTouched();
    if (!!this.addressForm.valid && !!this.ccForm.valid) {
      if (!!this.isEditMode) {
        this.billingAddress.country = 'US';
        this.billingAddress.line1 = AccountPaymentService.shortenAddress(this.billingAddress.line1, 30);
        this.billingAddress.city = AccountPaymentService.shortenAddress(this.billingAddress.city, 20);
        Object.assign(this.context.paymentMethod.card_profile.billing_address, this.billingAddress);
      }
      this.context.paymentMethod.card_profile.expiry_month = this.ccForm.get('cardExpirationMonth').value;
      this.context.paymentMethod.card_profile.expiry_year = this.ccForm.get('cardExpirationYear').value;
      this.closeDialog({ paymentMethod: this.context.paymentMethod });
    }
  }

  public enableEditAddress(): void {
    this.billingAddress.line1 = this.context?.paymentMethod?.card_profile?.billing_address?.line1;
    this.billingAddress.city = this.context?.paymentMethod?.card_profile?.billing_address?.city;
    this.billingAddress.state = this.context?.paymentMethod?.card_profile?.billing_address?.state;
    this.billingAddress.postal_code = this.context?.paymentMethod?.card_profile?.billing_address?.postal_code;
    this.isEditMode = true;
  }

  public addressDetails(eventFire: IAutoCompletePrediction): void {
    if (!!eventFire && !!this.addressForm.controls.line1?.value && this.addressForm.controls.line1?.value?.main_text) {
      const event = this.addressForm.controls.line1?.value;
      if (!!event.place_id) {
        this.appState.loading = true;
        //this is a default value until address have the value from api
        this.addressForm.controls.line1.setValue(event?.main_text);
        this.placesAutoCompleteService.findDetailedAddressFields(event.place_id).subscribe((place) => {
          this.streetSearchText = !!place.address1 && place.address1.length > 0 ? place.address1 : null;
          const displayedAddressModel = this.getAddressValues(place, event.main_text);
          const address = `${displayedAddressModel?.line1}, ${displayedAddressModel?.city
            }, ${displayedAddressModel?.state} ${displayedAddressModel?.postal_code
              ? displayedAddressModel?.postal_code
              : ''
            }`;
          this.addressForm.controls.line1.setValue(address);
          this.billingAddress.line1 = this.addressForm.get('line1').value;
          this.addressForm.controls.city.setValue(this.billingAddress.city);
          this.addressForm.controls.state.setValue(this.billingAddress.state);
          this.addressForm.controls.postal_code.setValue(this.billingAddress.postal_code);
          this.appState.loading = false;
        }, (error) => {
          this.appState.loading = false;
          console.warn(`Google can't find details for place: ${event.place_id}`, error);
          this.getAddressValues(this.billingAddress, event.main_text);
        });
      } else {
        console.warn(`Google can't find place: ${event.main_text}`);
        this.getAddressValues(this.billingAddress, event.main_text);
      }
    } else {
      this.getAddressValues(this.billingAddress, this.billingAddress?.line1);
    }
  }

  public findPlace(keyword: ''): Observable<Array<IAutoCompletePrediction>> {
    this.filteredOptions = this.placesAutoCompleteService.findAddress(keyword);
    this.filteredOptionsSubscription = this.filteredOptions.subscribe();
    return this.filteredOptions;
  }
  public changedAddress(): void {
    this.findPlace(this.addressForm.controls.line1.value);
  }
  public closeDialog(action?: any): void {
    this.beforeDismiss();
    this.dialog.close(action);
  }

  private getAddressValues(placeInfo: any, searchTerms?: string): IPaymentAddress {
    let address: IPaymentAddress = {
      line1: !!placeInfo?.line1? placeInfo?.line1 : placeInfo?.address1,
      state: placeInfo.state ? placeInfo.state.toUpperCase() : placeInfo.state,
      city: placeInfo.city,
      country: placeInfo.country || 'United States',
      postal_code: !!placeInfo?.postal_code? placeInfo?.postal_code : placeInfo?.postalCode
    } as IPaymentAddress;
    if (!!searchTerms && typeof searchTerms === 'string') {
      if (!!this.streetSearchText) {
        if (!searchTerms.match(this.streetSearchText)) {
          this.streetSearchText = null;
        }
      }
      address.line1 = !!this.streetSearchText ? address.line1 : searchTerms.trim();
    }
    address = this.removeEmptyValues(address);
    return Object.assign(this.billingAddress, address);
  }

  private removeEmptyValues(obj): any {
    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmptyValues(obj[key]);
      } else if (obj[key] == null || obj[key] === '') {
        delete obj[key];
      }
    });
    return obj;
  }

}
