import { Component, Inject, OnInit } from '@angular/core';
import {
  IUserPlan,
} from '@ztarmobile/zwp-service-backend';
import { Router } from '@angular/router';
import { ToastrHelperService } from 'src/services/toast-helper.service';
import { PlatformLocation } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountPaymentProfile } from '@ztarmobile/zwp-service-backend-v2/lib/models';
import { UserAccountService } from '@ztarmobile/zwp-service-backend-v2';
import { UserAccountService as UserAccountServiceV1 } from '@ztarmobile/zwp-service-backend';
import { AppState } from '@app/app.service';
import { ACCOUNT_ROUTE_URLS } from '@app/app.routes.names';
import { UserDataService } from '@services/user-data.service';
export class PaymentModalContext {
  public title: string;
  public subtitle: string;
  public userPlan: IUserPlan;
  public methodsList: Array<AccountPaymentProfile> = [];
  public customClass: string;
  public accountSettingsRoute?: string;
}

@Component({
  selector: 'app-manage-payment-modal-context',
  templateUrl: './manage-payment-modal-context.component.html'
})
export class ManagePaymentModalContextComponent implements OnInit {
  public setDefaultRequest: boolean;
  public expiredMethodsList: Array<AccountPaymentProfile> = [];
  public nonExpiredMethodsList: Array<AccountPaymentProfile> = [];
  public methodsList: Array<AccountPaymentProfile> = [];
  public context: PaymentModalContext;
  public selectedMethodId: string;
  public displayRequiredMsg = false;
  public currentYear;
  public currentMonth;
  public currentDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private toastHelper: ToastrHelperService,
    private userAccountService: UserAccountService,
    private userDataService: UserDataService,
    private router: Router,
    private appState: AppState,
    public dialog: MatDialogRef<PaymentModalContext>,
    private location: PlatformLocation) {
    this.context = data;
    this.currentDate = new Date();
    this.currentMonth = this.currentDate.getMonth() + 1;
    this.currentYear = this.currentDate.getFullYear();
    location.onPopState(() => { this.beforeDismiss(); this.dialog.close(); });
  }

  ngOnInit(): void {
    this.methodsList = this.context?.methodsList;
    if (this.methodsList?.length > 0) {
      this.currentMonth = this.currentMonth.toString();
      this.currentYear = this.currentYear.toString().slice(-2);
      if (this.currentMonth?.length === 1) {
        this.currentMonth = `0${this.currentMonth}`;
      }
      console.info('methods list popup ', this.methodsList);
      this.methodsList.map(method => {
        if (!!method && !!method?.card_profile && !!method?.card_profile?.expiry_year && !!method?.card_profile?.expiry_month) {
          this.currentMonth = this.currentMonth.toString();
          this.currentYear = this.currentYear.toString().slice(-2);
          let cardExpMonth = method?.card_profile?.expiry_month;
          let cardExpYear = method?.card_profile?.expiry_year?.slice(-2);
          // if card year less than currect year then its expired              
          if (parseInt(cardExpYear, 10) < parseInt(this.currentYear, 10)) {
            this.expiredMethodsList.push(method);
            Object.assign(method, { expired: true });
          } else if (parseInt(cardExpYear, 10) <= parseInt(this.currentYear, 10) && parseInt(cardExpMonth, 10) < parseInt(this.currentMonth, 10)) {
            this.expiredMethodsList.push(method);
            Object.assign(method, { expired: true });
          } else {
            Object.assign(method, { expired: false });
            this.nonExpiredMethodsList.push(method);
          }
        }
      });
    }
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  public cardSelected(id): void {
    this.selectedMethodId = id;
    this.displayRequiredMsg = false;
  }

  public closeDialog(data?: any): void {
    this.beforeDismiss();
    this.dialog.close(data);
  }

  public makePaymentMethodAsDefault(methodKey): void {
    if (methodKey) {
      if (this.setDefaultRequest) {
        return;
      }
      this.displayRequiredMsg = false;
      const userPlan = this.context?.userPlan;
      this.setDefaultRequest = true;
      this.appState.loading = true;
      console.info('method key ', methodKey )
      this.userAccountService.updateDefaultPayment(userPlan?.mdn, { paymentId: methodKey, isDefault: true, planId: userPlan?.id }).then(() => {
        setTimeout(() => {
          this.userDataService.callSyncApi(this.context?.userPlan?.id, this.context?.userPlan?.mdn).then((data) => {
            this.executeActionForClose(methodKey, data);
          }, (error) => {
            this.executeActionForClose(methodKey);
          });
        }, 1000);
      }, (error) => {
        this.setDefaultRequest = false;
        this.toastHelper.showAlert(error?.error?.errors[0]?.message);
        this.closeDialog('error');
      });
    } else {
      this.displayRequiredMsg = true;
    }
  }

  public executeActionForClose(methodKey, account?): void {
    this.setDefaultRequest = false;
    this.appState.loading = false;
    this.toastHelper.showSuccess('Default payment method set, it may take about 1 minute before the account getting the effect.');
    this.closeDialog({key: methodKey, accountData: !!account? account: null});
  }
  public goToSettings(addCard?:boolean): void {
    this.closeDialog();
    const params = {};
    if(!!addCard) {
      params[ACCOUNT_ROUTE_URLS.PARAMS.ADD_CARDS] = true;
    } else {
      params[ACCOUNT_ROUTE_URLS.PARAMS.MANAGE_CARDS] = true;
    }
    this.router.navigate([this.context.accountSettingsRoute, params]);
  }

  public checkBoxLabel(item: AccountPaymentProfile): string {
    const addressLabel = item?.card_profile?.brand;
    return addressLabel?.toLowerCase();
  }
}
