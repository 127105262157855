<div class="modal">
  <div class="container model-container {{context?.customClass}}">
    <div class="modal-header">
      <h4 class="modal-heading">{{context?.title}}</h4>
      <img src="assets/icon/popup-close-icon.svg" *ngIf="!setDefaultRequest" (click)="closeDialog()" class="close-icon"
        width="32" height="32" alt="Close Icon">
    </div>
    <div class="modal-body" [class.more-bottom]="nonExpiredMethodsList?.length <= 0 || methodsList?.length <= 0">
      <ng-container *ngIf="nonExpiredMethodsList?.length > 0">
        <p class="smaller modal-note">{{context?.subtitle}}</p>
        <div class="account-address-details-container highlight-card" *ngFor="let methodItem of nonExpiredMethodsList"
          [class.red-border]="!!displayRequiredMsg" (click)="cardSelected(methodItem?.payment_profile_id)">
          <div class="custom-checkbox">
            <div class="checkbox">
              <input title="{{methodItem?.card_profile?.brand}}" type="radio" name="shippingAddress"
                [id]="methodItem.payment_profile_id" [value]="methodItem"
                [checked]="selectedMethodId === methodItem.payment_profile_id">
              <label [for]="methodItem.payment_profile_id"></label>
            </div>
            <div class="checkbox-details">
              <p class="smaller custom-checkbox-label">
                <b>{{checkBoxLabel(methodItem)}}</b>
              </p>
              <p class="smaller">Ending in {{methodItem?.card_profile?.last_4}},</p>
              <p class="smaller">Expiry date:
                {{methodItem?.card_profile?.expiry_month}}/{{methodItem?.card_profile?.expiry_year}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="nonExpiredMethodsList?.length <= 0 && expiredMethodsList?.length === 1" id="expired-card">
        <p id="expired-message">Your saved credit card has expired.</p>
        <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment method</a>, or <a
            (click)="goToSettings(true)">add a new one</a>.</p>
      </div>
      <div *ngIf="nonExpiredMethodsList?.length <= 0 && expiredMethodsList?.length > 1" id="expired-cards">
        <p id="expired-message">Your saved credit cards have expired.</p>
        <p id="expired-links">Kindly <a (click)="goToSettings()">update your payment methods</a>, or <a
            (click)="goToSettings(true)">add a new one</a>.</p>
      </div>
      <div *ngIf="methodsList?.length <= 0" id="no-payments">
        <p>You do not have saved credit cards in your profile</p>
        <a (click)="goToSettings(true)">you can add one from profile settings.</a>
      </div>
      <div class="row form-section modal-actions" *ngIf="nonExpiredMethodsList?.length > 0">
        <p class="smaller modal-hint">You can also add another payment method in <a class="modal-link" (click)="goToSettings(true)">Profile
            Settings</a></p>
        <p class="validation-message modal-hint" *ngIf="!!displayRequiredMsg"><b>Select a payment method to proceed </b>
        </p>
        <div class="flex-actions">
          <button class="button primary" (click)="makePaymentMethodAsDefault(selectedMethodId)"
            [class.disabled]="setDefaultRequest" [disabled]="setDefaultRequest" [uiBlockButton]="setDefaultRequest">
            Save
          </button>
          <a (click)="closeDialog()">Cancel</a>
        </div>
      </div>
    </div>
  </div>
</div>