<header class="header" id="header">
  <div class="header-container page-section">
    <div class="logo-part" *ngIf="innerWidth < 1400">
      <div class="responsive-section">
        <div class="hamburger-container" (click)="toggleMenu()" *ngIf="!showMenu">
          <img src="assets/icon/hamburger-menu-icon.svg" alt="Hamburger Icon" width="24" height="24">
        </div>
        <div class="hamburger-menu page-section" *ngIf="!!showMenu">
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.MDN_QUICK_TOP_UP]"
                (click)="toggleMenu(); showSubMenu(null)"><img src="assets/icon/quick-top-up-black-icon.svg" width="20"
                  height="20" class="label-img" alt="Star Icon"> Quick Top-Up</a>
              <img src="assets/icon/black-plus-icon.svg" width="24" height="24" alt="Plus Icon">
            </div>
          </div>
          <hr>
          <ng-container *ngIf="!isLoggedIn">
            <div class="link">
              <div class="label-part">
                <a [routerLink]="[LOGIN_ROUTE_URLS.BASE + '/' + LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
                  [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu(); showSubMenu(null)"><img
                    src="assets/icon/user-black-icon.svg" width="20" height="20" class="label-img" alt="Star Icon">Sign
                  In</a>
              </div>
            </div>
            <hr>
          </ng-container>
          <ng-container *ngIf="!!isLoggedIn">
            <div class="link" *ngIf="!!isLoggedIn">
              <div class="label-part" (click)="showSubMenu('account')">
                <a><img src="assets/icon/user-black-icon.svg" width="20" height="20" class="label-img"
                    alt="Star Icon">My Account</a>
                <img src="assets/icon/header-arrow.svg" width="24" height="24" [class.rotate]="subMenu=== 'account'"
                  alt="Plus Icon">
              </div>
              <div class="sub-wrapper" *ngIf="subMenu=== 'account'">
                <button class="button tertiary" (click)="goToAccountSummary();">Account Summary</button>
                <button class="button tertiary" (click)="goToPurchasedPlans()">Purchased Plans</button>
                <button class="button tertiary" (click)="goToPayAndRefill()">Pay & Refill your Account</button>
                <button class="button tertiary" (click)="goToAddOns()">Plan Add-ons</button>
                <button class="button tertiary" (click)="goToOrders()">Your Orders</button>
                <button class="button tertiary" (click)="goToPaymentHistory()">Payment History</button>
                <button class="button tertiary" (click)="goToUsageHistory()">Usage History</button>
                <button class="button tertiary" (click)="goToManageDevice()">Manage Device & SIM</button>
                <button class="button tertiary" (click)="goToProfile()">Profile Settings</button>
                <button class="button tertiary" (click)="logout($event)">Log Out</button>
              </div>
            </div>
            <hr>
          </ng-container>
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[ROUTE_URLS.HOME]" (click)="toggleMenu(); showSubMenu(null)"><img
                  src="assets/icon/home-black-icon.svg" width="20" height="20" class="label-img"
                  alt="Star Icon">Home</a>
            </div>
          </div>
          <hr>
          <ng-container *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
            <div class="link">
              <div class="label-part">
                <a [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.PLANS_AND_FEATURES + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                  (click)="toggleMenu(); showSubMenu(null); onLinkClick($event, 'plans')"><img
                    src="assets/icon/shop-black-icon.svg" width="20" height="20" class="label-img"
                    alt="Star Icon">Plans</a>
              </div>
            </div>
            <hr>
          </ng-container>
          <ng-container *ngIf="!!isLoggedIn && !!userHasActivePlans">
            <div class="link">
              <div class="label-part" (click)="showSubMenu('shop')">
                <a><img src="assets/icon/shop-black-icon.svg" width="20" height="20" class="label-img" alt="Star Icon">
                  Shop</a>
                <img src="assets/icon/header-arrow.svg" width="24" height="24" [class.rotate]="subMenu=== 'shop'"
                  alt="Arrow Icon">
              </div>
              <div class="sub-wrapper active-plans" *ngIf="subMenu=== 'shop'">
                <div class="user-account-part">
                  <div class="mdn-cell">
                    <p class="title">Your Account</p>
                    <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                      (ngModelChange)="userPlanSelected($event)" [disabled]="disabled" [compareWith]="planCompare">
                      <option *ngFor="let plan of userPlans" [ngValue]="plan">
                        {{getSelectorTitle(plan)}}
                      </option>
                    </select>
                  </div>
                  <div class="actions-flex">
                    <button class="button tertiary" (click)="changePlan(); toggleMenu(); showSubMenu(null)"
                      [class.disabled]="!!isPortIn || billingRenewalPaid">Change Plan</button>
                    <button class="button tertiary" (click)="goToAddOns(); toggleMenu(); showSubMenu(null)"
                      [class.disabled]="!!isPortIn || !!isExpiredAccount">Order Add-ons</button>
                  </div>
                </div>
                <div class="new-account-part">
                  <p class="title">New Account</p>
                  <button class="button tertiary" (click)="goToPlans(); toggleMenu(); showSubMenu(null)">Purchase new
                    Plan</button>
                </div>
              </div>
            </div>
            <hr>
          </ng-container>
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu(); showSubMenu(null)"><img
                  src="assets/icon/phone-black-icon.svg" width="20" height="20" class="label-img" alt="Star Icon">Bring
                your Phone</a>
            </div>
          </div>
          <hr>
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.LANDING_COVERAGE]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                (click)="toggleMenu(); showSubMenu(null)"><img src="assets/icon/wifi-black-icon.svg" width="20"
                  height="20" class="label-img" alt="Star Icon">Coverage</a>
            </div>
          </div>
          <hr>
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.FAQS]" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" (click)="toggleMenu(); showSubMenu(null)"><img
                  src="assets/icon/support-black-icon.svg" width="20" height="20" class="label-img"
                  alt="Star Icon">Support</a>
            </div>
          </div>
          <hr>
          <div class="link">
            <div class="label-part">
              <a [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.SIM_CHECK]"
                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                (click)="toggleMenu(); showSubMenu(null)"><img src="assets/icon/activate-black-icon.svg" width="20"
                  height="20" class="label-img" alt="Star Icon">Activate</a>
            </div>
          </div>
        </div>
      </div>
      <a [routerLink]="[ROUTE_URLS.HOME]" (click)="toggleMenu(); showSubMenu(null)" *ngIf="!!showMenu">
        <img src="assets/img/freeup-mobile-logo.svg" class="logo" width="160" height="40" alt="FREEUP Mobile Logo">
      </a>
    </div>
    <div class="logo-part" *ngIf="!showMenu">
      <a [routerLink]="[ROUTE_URLS.HOME]">
        <img src="assets/img/freeup-mobile-logo.svg" class="logo" width="160" height="40" alt="FREEUP Mobile Logo">
      </a>
    </div>
    <div class="header-links" *ngIf="innerWidth > 1399">
      <div class="link" *ngIf="!isLoggedIn || (!!isLoggedIn && !userHasActivePlans)">
        <a [routerLink]="[SHOP_ROUTE_URLS.BASE + '/'+ PLANS_SHOP_ROUTE_URLS.BASE + '/' + PLANS_SHOP_ROUTE_URLS.NEW_PLAN]"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
          (click)="onLinkClick($event, 'plans')">Plans</a>
      </div>
      <div class="link menu-item" *ngIf="!!isLoggedIn && !!userHasActivePlans">
        <a>Shop <img src="assets/icon/header-arrow.svg" width="20" height="20" alt="Arrow Icon"></a>
        <div class="wrapper active-plans">
          <div class="user-account-part">
            <div class="mdn-cell">
              <p class="title">Your Account</p>
              <select class="mdn-list" id="phonePlan" name="phonePlan" [(ngModel)]="selectedPlan"
                (ngModelChange)="userPlanSelected($event)" [disabled]="disabled" [compareWith]="planCompare">
                <option *ngFor="let plan of userPlans" [ngValue]="plan">
                  {{getSelectorTitle(plan)}}
                </option>
              </select>
            </div>
            <div class="actions-flex">
              <button class="button tertiary" (click)="showMenu = true; changePlan()" [class.disabled]="!!isPortIn || billingRenewalPaid">Change Plan</button>
              <hr>
              <button class="button tertiary" (click)="showMenu = true; goToAddOns(); toggleMenu();"
                [class.disabled]="!!isPortIn || !!isExpiredAccount">Order Add-ons</button>
            </div>
          </div>
          <div class="border"></div>
          <div class="new-account-part">
            <p class="title">New Account</p>
            <button class="button tertiary" (click)="goToPlans()">Purchase new Plan</button>
            <hr>
          </div>
        </div>
      </div>
      <div class="link">
        <a [routerLink]="[ROUTE_URLS.BRING_PHONE]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Bring your Phone</a>
      </div>
      <div class="link">
        <a [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.LANDING_COVERAGE]"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Coverage</a>
      </div>
      <div class="link">
        <a [routerLink]="[SUPPORT_ROUTE_URLS.BASE+'/'+SUPPORT_ROUTE_URLS.FAQS]">Support</a>
      </div>
      <div class="link">
        <a [routerLink]="[ACTIVATION_ROUTE_URLS.BASE + '/' + ACTIVATION_ROUTE_URLS.SIM_CHECK]" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}">Activate</a>
      </div>
    </div>
    <div class="login-part">
      <ng-container *ngIf="innerWidth > 1399">
        <div class="link">
          <a [routerLink]="[SHOP_ROUTE_URLS.BASE + '/' + SHOP_ROUTE_URLS.MDN_QUICK_TOP_UP]" class="quick-top-up">QUICK
            TOP-UP<img src="assets/icon/primary-plus-icon.svg" width="20" height="20" alt="Plus Icon"></a>
        </div>
        <div class="border"></div>
        <div class="link" *ngIf="!isLoggedIn">
          <a [routerLink]="[LOGIN_ROUTE_URLS.BASE + '/' + LOGIN_ROUTE_URLS.LOGIN]" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}">SIGN IN</a>
        </div>

        <div class="link menu-item" *ngIf="!!isLoggedIn">
          <a>MY ACCOUNT <img src="assets/icon/header-arrow.svg" width="20" height="20" alt="Arrow Icon"></a>
          <div class="wrapper account">
            <button class="button tertiary" (click)="goToAccountSummary()">Account Summary</button>
            <hr>
            <button class="button tertiary" (click)="goToPurchasedPlans()">Purchased Plans</button>
            <hr>
            <button class="button tertiary" (click)="goToPayAndRefill()">Pay & Refill your Account</button>
            <hr>
            <button class="button tertiary" (click)="goToAddOns()">Plan Add-ons</button>
            <hr>
            <button class="button tertiary" (click)="goToOrders()">Your Orders</button>
            <hr>
            <button class="button tertiary" (click)="goToPaymentHistory()">Payment History</button>
            <hr>
            <button class="button tertiary" (click)="goToUsageHistory()">Usage History</button>
            <hr>
            <button class="button tertiary" (click)="goToManageDevice()">Manage Device & SIM</button>
            <hr>
            <button class="button tertiary" (click)="goToProfile()">Profile Settings</button>
            <hr>
            <button class="button tertiary" (click)="logout($event)">Log Out</button>
          </div>
        </div>
      </ng-container>
      <div class="shopping-icon" *ngIf="!showMenu" (click)="goToCart()">
        <img src="assets/icon/shopping-cart-icon.svg" width="24" height="24" alt="Shopping Icon">
        <p class="counter footer" *ngIf="hasCartItem && totalItems !== 0">{{totalItems}}</p>
      </div>
      <div class="close-icon" (click)="toggleMenu()" *ngIf="!!showMenu">
        <img src="assets/icon/primary-close-icon.svg" width="24" height="24" alt="Close Icon">
      </div>
    </div>
  </div>
</header>
<div class="outage-banner" *ngIf="!!outageBanner">
  <div class="flex">
    <img src="assets/icon/alert-icon.svg" width="32" height="32" alt="Alert Icon">
    <div class="banner-content">
      <h5 class="title">Network is currently experiencing a major service outage, impacting large portions of the
        country.</h5>
      <p class="desc">Engineers are working to restore service. At this time, there is no ETA for restoral, but we
        expect a quick resolution to this critical service outage.</p>
    </div>
  </div>
</div>